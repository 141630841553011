@import "~antd/dist/antd.less";

@primary-gray: #f5f7f9;
@primary-gray-dark: #ececec;
@primary-gray-darker: #c4c4c4;
@primary-blue: #1e90ff;
@primary-color: #ef4823;
@primary-black: #333235;
@border-color: #e8e8e8;
@primary-green: #16ac52;
@primary-white: #ffffff;
@gray-50: #f7fafc;
@gray-100: #edf2f7;
@gray-200: #e2e8f0;
@gray-300: #cbd5e0;
@gray-400: #a0aec0;
@gray-500: #718096;
@gray-600: #4a5568;
@gray-700: #2d3748;
@gray-800: #1a202c;
@gray-900: #171923;

.ant-list-header {
	padding: 0 !important;
	background-color: @gray-200;
}

.ant-list-empty-text {
	display: none !important;
}

.apexcharts-toolbar {
	z-index: 5 !important;
}

.ant-list-item {
	padding: 0 !important;
}

.ant-select-multiple .ant-select-selection-item {
	background: @gray-200 !important;
	border-radius: 100px !important;

	.ant-select-selection-item-content {
		color: @gray-600 !important;
	}
}

.ant-spin-nested-loading {
	width: 100% !important;
}

.chakra-modal__content {
	margin: 0 !important;
}

.ant-select-dropdown,
.ant-picker-dropdown {
	.ant-picker-panel-container {
		background: white !important;
	}

	z-index: 9999;
}

th.ant-table-cell {
	text-transform: capitalize;
	font-weight: bold !important;
}

p {
	margin: 0 !important;
}

.ant-select-selector {
	background-color: transparent !important;

	::placeholder {
		color: @gray-500;
	}
}

.ant-form-item {
	margin-bottom: 0 !important;
}

.ant-upload {
	width: 100% !important;
}

.ant-radio-button-wrapper {
	background-color: @gray-200 !important;
}

.ant-select-selector {
	border: none !important;
	box-shadow: none !important;

	&:active,
	&:focus {
		border: none !important;
		box-shadow: none !important;
	}
}

.ant-list-split .ant-list-item {
	border-bottom: 1px solid @border-color;
}

.ant-timeline-item-head {
	background-color: @primary-gray;
	border: 1px solid @border-color !important;
	width: 20px;
	height: 20px;
	display: grid;
	place-items: center;
	border-radius: 50px;
}

.ant-picker-calendar-date {
	border: 2px solid @gray-100 !important;
	background-color: @gray-200;
}

.ant-picker-calendar-date-today {
	background-color: @gray-50;
}

.ant-tabs-top > .ant-tabs-nav::before {
	border-bottom: 1px solid @border-color;
}

.ant-tabs-tab {
	padding: 5px;
}

.ant-picker-calendar-date-content {
	padding: 0px 5px;
	height: 50px !important;
}

.ant-list-header {
	background-color: @primary-white !important;
}

.ant-switch {
	background-color: @gray-300 !important;
	width: fit-content;

	&.ant-switch-checked {
		background-color: @primary-color !important;
	}
}

body,
html {
	background-color: @primary-gray;
	overflow: hidden;
}

::-webkit-scrollbar {
	display: block;
	width: 5px;
	height: 0;
	background: @primary-gray-dark;
}

#leftBarScroll::-webkit-scrollbar {
	display: block;
}

.ant-table-content::-webkit-scrollbar {
	height: 5px !important;
}

#my-modal {
	overflow-y: scroll;
	overflow-x: hidden;
	padding-right: 5px;

	&::-webkit-scrollbar {
		display: block;
	}
}

::-webkit-scrollbar-thumb {
	background: @primary-gray-darker;
	border-radius: 10px;
	cursor: n-resize;
}

p {
	svg {
		display: inline-block !important;
		margin: 0 !important;
	}
}

button {
	p {
		svg {
			display: inline-block !important;
			margin: 0 !important;
		}
	}
}

div {
	svg {
		display: inline-block !important;
	}
}

.tox-tinymce {
	border: none !important;
	border-radius: 0px !important;
}

.tox,
.tox-toolbar-overlord,
.tox-editor-header {
	background-color: transparent !important;
}

.tox.tox-toolbar__primary {
	background-color: transparent !important;
}

.ant-select-arrow {
	display: none;
}

.ant-select-selection-item {
	padding: 0 !important;
}

.ant-select-item.ant-select-item-option {
	padding: 0px 5px !important;
	height: 34px;
}

.contactTabs {
	.ant-tabs-tab.ant-tabs-tab-disabled {
		border: none !important;
		border-right: 1px solid @border-color !important;
		margin-top: 2rem !important;
		cursor: default;
		padding: 0 0 0 1rem !important;
	}

	.ant-tabs-tab {
		&:first-child {
			background-color: @primary-color !important;
			color: @primary-white !important;
			border-radius: 50px 0 0 50px !important;
			border: none !important;
			margin-bottom: 1rem !important;

			&.ant-tabs-tab-active {
				background-color: @primary-white !important;
				color: @primary-color !important;
				border: 1px solid @primary-gray !important;
				border-right: none !important;
			}
		}

		&:nth-child(2) {
			background-color: @primary-white !important;
			color: @primary-black !important;
			border-radius: 50px 0 0 50px !important;
			margin-bottom: 1rem !important;

			&.ant-tabs-tab-active {
				background-color: @primary-white !important;
				color: @primary-color !important;
				border: 1px solid @primary-gray !important;
				border-right: none !important;
			}
		}

    &.ant-tabs-tab-active {
      background-color: @primary-white !important;
      color: @primary-color !important;
      border: 1px solid @primary-gray !important;
      border-right: none !important;
      font-weight: 900 !important;
    }
    
  }
}
.ant-tabs-tab-btn {
  width: 100% !important;
  text-align: left !important;
}

hr {
	opacity: 0.5 !important;
	border-width: 1px !important;
	border-color: @border-color !important;
}

.ant-divider.ant-divider-horizontal {
	margin: 5px 0 !important;
}

.ruleGroup {
	background-color: #fff !important;
	border-color: #e8e8e8 !important;
}

/* .rule-value-list-item {
  background-color: bisque !important;
  color: green !important;
} */

.queryBuilder .chakra-select__wrapper {
	width: max-content;
	display: inline-block;
	margin-left: 0.5rem;
}

.queryBuilder .chakra-input {
	width: auto;
	display: inline-block;
	margin-left: 0.5rem;
}

.queryBuilder .chakra-radio-group {
	display: inline-block;
}

.tox.tox-tinymce-aux,
.tox-fullscreen .tox.tox-tinymce-aux {
	z-index: 4000;
}

.my-select .mantine-select-dropdown {
	z-index: 9999 !important;
}

@gray-50: #F7FAFC;@gray-100: #EDF2F7;@gray-200: #E2E8F0;@gray-300: #CBD5E0;@gray-400: #A0AEC0;@gray-500: #718096;@gray-600: #4A5568;@gray-700: #2D3748;@gray-800: #1A202C;@gray-900: #171923;@primary-color: #EF4823;@secondary-color: #2A2D3E;@text-color: #333235;@text-color-secondary: #424242;@heading-color: #3e3e3e;@primary-gray: #d4d4d4;@border-radius-base: 3px;@border-color: #e8e8e8;@border-color-inverse: @border-color;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);@font-family: Open Sans, sans-serif;@font-size-base: 12px;@font-size-lg: 14px;@font-size-sm: 11px;@font-weight-base: 400;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@info-color: #1890ff;@link-color: @primary-color;@calendar-bg: transparent;@calendar-input-bg: transparent;@calendar-border-color: @border-color-inverse;@calendar-item-active-bg: @gray-50;@calendar-full-bg: transparent;@calendar-full-panel-bg: transparent;